import { useCallback, useEffect, useState } from 'react';

import { useDeclineClaim } from 'Claim/_services/hooks/claims/useDeclineClaim';
import ReasonModal from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ReasonModal';
import { CLAIM_UPDATE_STATUS } from 'Claim/Claims/context/ClaimGroupsActions';
import { useClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsReducer';
import IconButton from 'Components/Shared/BestButton/IconButton';
import { PERMISSION } from 'Constants/permissions.constants';

type Props = {
  claimId: number;
  isFinished?: boolean;
};

const DeclineButton = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [canCloseModal, setCanCloseModal] = useState(true);

  const [doDecline, { loading, error, data }] = useDeclineClaim(props.claimId, reason);
  const { dispatch } = useClaimGroupsState();

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    if (!canCloseModal) {
      return;
    }
    setOpenModal(false);
  }, [canCloseModal]);

  const handleChangeDeclineReason = useCallback((value: string) => {
    if (value.trim() === '') {
      setReason(undefined);
      setErrorMessage('Please provide a reason');
      return;
    }
    setReason(value);
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      setCanCloseModal(true);
    }
  }, [error]);

  const onDecline = useCallback(() => {
    setCanCloseModal(false);
    doDecline();
  }, [doDecline]);

  useEffect(() => {
    if (data === undefined) {
      setCanCloseModal(true);
      return;
    }
    dispatch({
      type: CLAIM_UPDATE_STATUS,
      id: props.claimId,
      action: 'DECLINE',
      supplierComment: reason ?? '',
    });
    setCanCloseModal(true);
    handleCloseModal();
  }, [reason, dispatch, props.claimId, handleCloseModal, data]);

  useEffect(() => {
    if (!openModal) {
      setErrorMessage(undefined);
      setReason(undefined);
    }
  }, [openModal]);

  return (
    <>
      <IconButton
        tooltip={
          !props.isFinished
            ? 'This claim is not finished and cannot be declined. Please reach out to the buyer.'
            : ''
        }
        disabled={!props.isFinished}
        loading={loading}
        iconName="view"
        onClick={handleOpenModal}
        variant="contained"
        colorSchema={{ background: 'error-red', foreground: 'white' }}
        disableElevation
        noMargin
        permission={PERMISSION.VP_CLAIM_DECLINE}
      >
        Decline
      </IconButton>
      <ReasonModal
        open={openModal}
        onClose={() => !loading && handleCloseModal()}
        fromDecline
        onUpdate={onDecline}
        onChange={handleChangeDeclineReason}
        message={errorMessage}
      />
    </>
  );
};

export default DeclineButton;
