import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProject: ProjectDto;
  deleteProject: ProjectDto;
  setPapskilt: ProjectDto;
  removePapskilt: ProjectDto;
};


export type MutationAddProjectArgs = {
  projectName: Scalars['String'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['String'];
};


export type MutationSetPapskiltArgs = {
  projectId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


export type MutationRemovePapskiltArgs = {
  projectId: Scalars['String'];
};

export type PapskiltChangedEvent = {
  __typename?: 'PapskiltChangedEvent';
  id: Scalars['String'];
  projectId: Scalars['String'];
  payload: PapskiltDto;
};

export type PapskiltDto = {
  __typename?: 'PapskiltDto';
  message?: Maybe<Scalars['String']>;
};

export type ProjectDto = {
  __typename?: 'ProjectDto';
  id: Scalars['String'];
  name: Scalars['String'];
  papskilt?: Maybe<PapskiltDto>;
};

export type Query = {
  __typename?: 'Query';
  projects: Array<ProjectDto>;
  project: ProjectDto;
  papskilt: PapskiltDto;
};


export type QueryProjectArgs = {
  id: Scalars['String'];
};


export type QueryPapskiltArgs = {
  projectId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  papskiltChanged: PapskiltChangedEvent;
};


export type SubscriptionPapskiltChangedArgs = {
  projectId: Scalars['String'];
};

export type GetPapskiltQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetPapskiltQuery = (
  { __typename?: 'Query' }
  & { papskilt: (
    { __typename?: 'PapskiltDto' }
    & Pick<PapskiltDto, 'message'>
  ) }
);

export type PapskiltChangedSubscriptionVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type PapskiltChangedSubscription = (
  { __typename?: 'Subscription' }
  & { papskiltChanged: (
    { __typename?: 'PapskiltChangedEvent' }
    & { payload: (
      { __typename?: 'PapskiltDto' }
      & Pick<PapskiltDto, 'message'>
    ) }
  ) }
);


export const GetPapskiltDocument = gql`
    query getPapskilt($projectId: String!) @api(name: papskilt) {
  papskilt(projectId: $projectId) {
    message
  }
}
    `;

/**
 * __useGetPapskiltQuery__
 *
 * To run a query within a React component, call `useGetPapskiltQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPapskiltQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPapskiltQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetPapskiltQuery(baseOptions: Apollo.QueryHookOptions<GetPapskiltQuery, GetPapskiltQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPapskiltQuery, GetPapskiltQueryVariables>(GetPapskiltDocument, options);
      }
export function useGetPapskiltLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPapskiltQuery, GetPapskiltQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPapskiltQuery, GetPapskiltQueryVariables>(GetPapskiltDocument, options);
        }
export type GetPapskiltQueryHookResult = ReturnType<typeof useGetPapskiltQuery>;
export type GetPapskiltLazyQueryHookResult = ReturnType<typeof useGetPapskiltLazyQuery>;
export type GetPapskiltQueryResult = Apollo.QueryResult<GetPapskiltQuery, GetPapskiltQueryVariables>;
export const PapskiltChangedDocument = gql`
    subscription papskiltChanged($projectId: String!) @api(name: papskilt) {
  papskiltChanged(projectId: $projectId) {
    payload {
      message
    }
  }
}
    `;

/**
 * __usePapskiltChangedSubscription__
 *
 * To run a query within a React component, call `usePapskiltChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePapskiltChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapskiltChangedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePapskiltChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PapskiltChangedSubscription, PapskiltChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PapskiltChangedSubscription, PapskiltChangedSubscriptionVariables>(PapskiltChangedDocument, options);
      }
export type PapskiltChangedSubscriptionHookResult = ReturnType<typeof usePapskiltChangedSubscription>;
export type PapskiltChangedSubscriptionResult = Apollo.SubscriptionResult<PapskiltChangedSubscription>;