import React, { ReactElement, useMemo } from 'react';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { AxiosError } from 'axios';

import { Claim, ClaimsResponse, GetClaimLandingPageGroupsParams } from 'Claim/_types/claims';
import ClaimGroupsTableHelper from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/ClaimGroupsTableHelper';
import ClaimGroupDetail from 'Claim/Claims/ClaimGroups/ExpandedContent/Table';
import { useClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsReducer';
import { useMultipleUrlParams } from 'Components/Hooks/useUrlParams/useMultipleUrlParams';
import Table from 'Components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  closed: {
    backgroundColor: theme.palette.grey[200],
    opacity: 0.75,
  },
}));

interface Props {
  data: ClaimsResponse | undefined;
  params: GetClaimLandingPageGroupsParams;
  setShouldFetch: (value: boolean) => void;
  loading: boolean;
  error: AxiosError<any> | undefined;
}

const ClaimGroups = (props: Props): ReactElement => {
  const { state } = useClaimGroupsState();
  const classes = useStyles();

  const rows = useMemo(
    () => state.groups.map((group) => group.group) ?? props.data?.content ?? [],
    [state.groups, props.data?.content]
  );
  const queryParams = useMultipleUrlParams<GetClaimLandingPageGroupsParams>({ ...props.params });
  const cols = ClaimGroupsTableHelper({
    queryParams,
  });

  const pagination = useMemo(
    () => ({
      pageSize: props.data?.size ?? +(queryParams.size?.value ?? 0),
      totalItems: +(queryParams.size.value ?? 1) * (+(queryParams.page.value ?? 1) + 1),
      currentPage: props.data?.pageable.pageNumber ?? +(queryParams.page?.value ?? 0),
      totalPages: props.data?.number ?? 0,
      first: props.data?.first ?? false,
      last: props.data?.last ?? false,
      empty: props.data?.numberOfElements !== 0,
      onPageChange: (_page: number) => {
        queryParams.page.setValue(_page);
        props.setShouldFetch(true);
      },
      onPageSizeChange: (pageSize: number) => {
        queryParams.size.setValue(pageSize);
        props.setShouldFetch(true);
      },
    }),
    [props, queryParams.size, queryParams.page]
  );

  return (
    <Table<Claim>
      columns={cols}
      rows={rows}
      primaryKey="claim.id"
      tableName="claim-landing-groups"
      options={{
        disableRefetchButton: false,
        searchBar: true,
        expandable: true,
        toolbar: true,
        showTableName: false,
        dontAddExpandToUrl: true,
        stickyHeader: true,
      }}
      defaultSearch="claim.number"
      defaultSearchLabel="Claim Number"
      loading={props.loading}
      error={props.error}
      pagination={pagination}
      onShouldRefreshData={() => props.setShouldFetch(true)}
      onApplyFilters={() => {
        queryParams.page.setValue(0);
        props.setShouldFetch(true);
      }}
      ExpandedRowContent={ClaimGroupDetail}
      customStyling={{
        customRowClassName: (row: Claim) => {
          return row.closed ? classes.closed : '';
        },
      }}
    />
  );
};

export default ClaimGroups;
