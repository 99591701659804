import { Grid } from '@mui/material';

import ConfirmButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ConfirmButton';
import DeclineButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/DeclineButton';
import PrintClaim from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/PrintClaim';
import UnconfirmButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/UnconfirmButton';

export const renderWhenStatusIsConfirmed = (claimId: number) => {
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
      <Grid item>
        <UnconfirmButton claimId={claimId} />
      </Grid>
    </Grid>
  );
};
export const renderWhenStatusIsNotConfirmed = (claimId: number, isFinished?: boolean) => {
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
      <Grid item>
        <DeclineButton claimId={claimId} isFinished={isFinished} />
      </Grid>
      <Grid item>
        <ConfirmButton claimId={claimId} isFinished={isFinished} />
      </Grid>
    </Grid>
  );
};
export const renderWhenStatusIsOthers = (claimId: number) => {
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
    </Grid>
  );
};
