import { ReactElement, useState, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { DialogContent } from '@mui/material';

import { ReleaseItem } from './ReleaseItem';
import classes from './ReleaseNotes.module.scss';

interface Props {
  items: ReleaseItem[];
}
export const ReleaseNotes = (props: Props): ReactElement => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <DialogContent dividers classes={{ root: classes.dialogContentOverride }}>
      <div className={classes.container}>
        <div className={classes.menu}>
          {props.items.map((item: ReleaseItem, i: number): ReactElement => {
            return (
              <div
                className={[classes.menuItem, i === selectedItem ? classes.active : null].join(' ')}
                key={i}
                onClick={(): void => setSelectedItem(i)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div className={classes.content}>
          {props.items.map((item: ReleaseItem, i: number): ReactNode => {
            return (
              selectedItem === i && <div key={`content-${i}`}>{ReactHtmlParser(item.content)}</div>
            );
          })}
        </div>
      </div>
    </DialogContent>
  );
};
