import React, { useCallback, useMemo } from 'react';

import { ClaimDetail, ClaimDetailLine, UsedOnClaim } from 'Claim/_types/claims';
import { formatAmountToUS } from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/formatAmountToUS';
import Table from 'Components/Table';
import { TableColumn } from 'Components/Table/state/_types_/TableColumn';

interface Props {
  claimDetail: ClaimDetail | undefined;
}

const ExpandedClaimLines = (props: Props) => {
  const tableData = useMemo(() => props.claimDetail?.lines ?? [], [props.claimDetail?.lines]);

  const renderUsedOnClaim = useCallback((usedOnClaim: UsedOnClaim[]) => {
    return usedOnClaim.map((claim) => (
      <div key={`used-on-claims-${claim.id}-${claim.number}`}>{claim.number}</div>
    ));
  }, []);

  const renderOrderTotal = useCallback((orderTotal: { amount: number; currency: string }) => {
    const formattedOrderTotal = formatAmountToUS(orderTotal.amount);

    return `${formattedOrderTotal} ${orderTotal.currency}`;
  }, []);

  const cols = useMemo(
    (): TableColumn<ClaimDetailLine>[] => [
      {
        property: 'orderNumber',
        label: 'Order Number',
        resolver: (item) => item.orderNumber,
      },
      {
        property: 'style',
        label: 'Style Name',
        resolver: (item) => item.style.name,
      },
      {
        property: 'deliveryDate',
        label: 'Delivery Date',
        resolver: (item) => item.deliveryDate,
      },
      {
        property: 'usedOnClaim',
        label: 'Used On Claim',
        resolver: (item) => renderUsedOnClaim(item.usedOnClaim),
      },
      {
        property: 'orderTotal',
        label: 'Order Total',
        resolver: (item) => renderOrderTotal(item.orderTotal),
      },
    ],
    [renderOrderTotal, renderUsedOnClaim]
  );

  return (
    <Table<ClaimDetailLine>
      columns={cols}
      rows={tableData}
      primaryKey="orderNumber"
      tableName="claim-details"
      options={{
        disableRefetchButton: true,
        expandable: false,
        toolbar: false,
        showTableName: false,
        dontAddExpandToUrl: true,
      }}
    />
  );
};

export default ExpandedClaimLines;
