import { ClaimState } from 'Claim/_types/claims';
import {
  renderWhenStatusIsConfirmed,
  renderWhenStatusIsNotConfirmed,
  renderWhenStatusIsOthers,
} from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/renderActionBarHelper';

type Props = {
  claimId?: number;
  status: ClaimState | undefined;
  isFinished?: boolean;
};

const ActionBar = (props: Props) => {
  if (!props.claimId) {
    return null;
  }

  if (props.status === 'CONFIRMED') {
    return renderWhenStatusIsConfirmed(props.claimId ?? 0);
  }
  if (props.status === 'NOT_CONFIRMED') {
    return renderWhenStatusIsNotConfirmed(props.claimId ?? 0, props.isFinished);
  } else {
    return renderWhenStatusIsOthers(props.claimId ?? 0);
  }
};

export default ActionBar;
