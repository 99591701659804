import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';

import { useGetClaims } from 'Claim/_services/hooks/claims/getClaimsService';
import useQueryParams from 'Claim/_services/hooks/claims/useQueryParams';
import { Claim as ClaimType, GetClaimLandingPageGroupsParams } from 'Claim/_types/claims';
import ClaimGroups from 'Claim/Claims/ClaimGroups';
import { CLAIM_ADD_GROUP, CLAIM_CLEAR_ALL_GROUPS } from 'Claim/Claims/context/ClaimGroupsActions';
import { useClaimGroupsReducer } from 'Claim/Claims/context/ClaimGroupsReducer';
import { ClaimGroupsContext } from 'Claim/Claims/context/ClaimsGroupsProvider';

type GroupDataMap = { [key: string]: ClaimType };

const Claim = () => {
  const [groupData, setGroupData] = useState<ClaimType[] | undefined>();
  const [shouldFetch, setShouldFetch] = useState(true);

  const [state, dispatch] = useClaimGroupsReducer({
    groups: [],
  });

  const { queryParams, defaultParams } = useQueryParams();
  const [params, setParams] = useState<GetClaimLandingPageGroupsParams>(defaultParams);

  const [fetch, { data, loading, error }] = useGetClaims(params);

  const refetchRef = useRef(fetch);

  const doClean = useCallback(async () => {
    dispatch({ type: CLAIM_CLEAR_ALL_GROUPS });

    setParams((prevVal) => ({
      ...prevVal,
      ...queryParams,
    }));
    setTimeout(() => {
      refetchRef.current();
    }, 1);
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    fetch();
    setShouldFetch(false);
  }, [fetch, shouldFetch]);

  useEffect(() => {
    doClean();
  }, [doClean]);

  useEffect(() => {
    if (!data?.content) {
      return;
    }

    const newData = data.content.reduce((acc: GroupDataMap, group) => {
      acc[`${group?.claim?.id}`] = group;
      return acc;
    }, {});

    setGroupData(Object.values(newData));
  }, [data?.content]);

  useEffect(() => {
    if (!groupData) {
      return;
    }

    Object.values(groupData).forEach((group) => {
      dispatch({ type: CLAIM_ADD_GROUP, group });
    });
  }, [dispatch, groupData]);

  const memoValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ClaimGroupsContext.Provider value={memoValue}>
      <ClaimGroups
        params={params}
        setShouldFetch={setShouldFetch}
        data={data}
        loading={loading}
        error={error}
      />
    </ClaimGroupsContext.Provider>
  );
};

export default Claim;
