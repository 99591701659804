import { Box, Typography } from '@mui/material';

type Props = {
  isClosed: boolean;
  claimNumber: string;
};

const ClaimNumberCellResolver = (props: Props) => {
  return (
    <Box>
      <Typography>{props.claimNumber}</Typography>
      {props.isClosed && <Typography variant="caption">- closed -</Typography>}
    </Box>
  );
};

export default ClaimNumberCellResolver;
